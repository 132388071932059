import React from 'react';
import SongCard from './song_card'

class AlreadyPlayedBox extends React.Component {
  construct_song_cards() {
    return this.props.already_played_songs.map(song => {
      return (
        <SongCard song_name={song} key={song} thumbnail={this.props.dict[song]['Thumbnail']} play={this.props.play}></SongCard>
      );
    })
  }
  render() {
    let list = this.construct_song_cards();
    return (
      <div className='Already-played-box'>
       {list}
      </div>
    );
  }
  }

export default AlreadyPlayedBox