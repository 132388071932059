import React from 'react';

class TitleComponent extends React.Component {
    render() {
      return (
        <div className='Title-component'>
            <h1>
                ItsaNother Playlist Player
            </h1>
        </div>

      );
    }
  }

export default TitleComponent