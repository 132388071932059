import React from 'react';
import SongCard from './song_card'

class UnplayedBox extends React.Component {
    construct_song_cards() {
      return this.props.unplayed_songs.map(song => {
        return (
          <SongCard song_name={song} play={this.props.play} key={song} thumbnail={this.props.dict[song]['Thumbnail']}></SongCard>
        );
      })
    }
    render() {
      console.log(this.props.dict)
      let list = this.construct_song_cards();
      return (
        <div className='Unplayed-box'>
         {list}
        </div>
      );
    }
  }

export default UnplayedBox