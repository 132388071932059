import React from 'react';
import AlreadyPlayedBox from './already_played_box'
import UnplayedBox from './unplayed_box'
import YoutubeBox from './youtube_box';

class MainContainer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        already_played: [],
        unplayed: [],
        current_video: '',
        initial_play: false
      }
      this.shuffle_array = this.shuffle_array.bind(this)
      this.shuffle_unplayed = this.shuffle_unplayed.bind(this)
      this.play_unplayed = this.play_unplayed.bind(this)
      this.play_youtube = this.play_youtube.bind(this)
    }
    componentDidMount() {
      this.setState({unplayed: this.props.video_names, initial_play: true})
    }
    shuffle_array(array) {
      for(let i = array.length-1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
    }
    shuffle_unplayed() {
      var clone_video_names = [...this.state.unplayed];
      this.shuffle_array(clone_video_names)
      this.setState({unplayed: clone_video_names})
    }
    play_unplayed(song_name = null, initial = false) {
      if (song_name === null) {
        song_name = this.state.unplayed[0]
      }
      
      var new_unplayed = this.state.unplayed.filter(function(value, index, arr){return song_name !== value})
      var new_already_played = [song_name].concat(this.state.already_played)

      if (initial) {
        this.setState({unplayed: new_unplayed, already_played: new_already_played, current_video: song_name, initial_play: false})
      }
      else if (new_unplayed.length === 0) {
        this.setState({unplayed: new_already_played, already_played: new_unplayed, current_video: song_name})
      }
      else {
        this.setState({unplayed: new_unplayed, already_played: new_already_played, current_video: song_name})
      }
    }
    play_youtube(song_name) {
      this.setState({current_video: song_name})
    }
    componentDidUpdate(prevProps) {
      if(this.props.shuffle) {
        this.shuffle_unplayed()
        this.props.shuffle_completed()
      }
      if(this.state.initial_play) {
        this.play_unplayed(null, true)
      }
      if (this.props.searched_song_name) {
        if (this.state.already_played.includes(this.props.searched_song_name)) {
          this.play_youtube(this.props.searched_song_name)
        }
        else {
          this.play_unplayed(this.props.searched_song_name)
        }
        this.props.clear_search()
      }
    }
    render() {
      return (
        <div className='Main-container'>
            <AlreadyPlayedBox dict={this.props.video_dict} already_played_songs={this.state.already_played} play={this.play_youtube}></AlreadyPlayedBox>
            <YoutubeBox song={this.state.current_video} dict={this.props.video_dict} play={this.play_unplayed}></YoutubeBox>
            <UnplayedBox dict={this.props.video_dict} unplayed_songs={this.state.unplayed} play={this.play_unplayed}></UnplayedBox>
        </div>
      );
    }
  }

export default MainContainer