import React from 'react';
import ItsaPlaylist from './react_components/itsa_playlist'
import './react_components/react_components.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ItsaPlaylist></ItsaPlaylist>
      </header>
    </div>
  );
}


export default App;
