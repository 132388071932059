import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ProgressBar from 'react-bootstrap/ProgressBar'
import TitleComponent from './title_component'
import MainContainer from './main_container'
import BottomBar from './bottom_bar'

class ItsaPlaylist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playlist_id: '',
            video_dict: [],
            video_names: [],
            video_ids: [],
            video_durations: [],
            video_thumbnails: [],
            searched_song_name: '',
            playlist_detected: false,
            activate_new_playlist: false,
            form_input: '',
            requesting: false,
            fetched_ids: false,
            fetched_information: 0,
            shuffle: false
        }
        this.search_completed = this.search_completed.bind(this)
        this.clear_search = this.clear_search.bind(this)
        this.form_input = this.form_input.bind(this)
        this.construct_playlist_submission = this.construct_playlist_submission.bind(this)
        this.get_playlist_items_data = this.get_playlist_items_data.bind(this)
        this.shuffle_push = this.shuffle_push.bind(this)
        this.shuffle_pop = this.shuffle_pop.bind(this)
    }
    async componentDidMount() {
    }
    search_completed(song_name) {
      this.setState({searched_song_name: song_name})
    }
    clear_search() {
      this.setState({searched_song_name: ''})
    }
    form_input(event) {
      this.setState({form_input: event.target.value})
    }
    shuffle_push() {
      this.setState({shuffle: true})
    }
    shuffle_pop() {
      this.setState({shuffle: false})
    }
    construct_playlist_submission() {
      return (
        <div>
          <h1>Welcome to ItsaPlayer.xyz</h1>
          <h3 className='LandingMargins'>A simple webapp to properly shuffle and play a public Youtube playlist</h3>
          <h5 className='LandingMargins'>Insert your Youtube Playlist ID below to begin</h5>
          <Form style={{margin: "10px"}}>
            <Form.Group controlId="playlist_id">
              <Form.Control onChange={this.form_input} placeholder="Playlist ID" />
            </Form.Group>
          </Form>
          <Button style={{marginBottom: "10px"}} variant="outline-info" onClick={() => this.get_playlist_items_data()}>Submit</Button>
          <h5>The Youtube playlist must be set to public. The ID can be found in the URL of the full playlist.</h5>
          <img style={{maxWidth: "70vw"}} src='images/YTID.PNG' alt='ID is after "list=" in the URL'></img>
        </div>
      );
    }
    async get_playlist_items_data() {
      var base_url = 'https://www.googleapis.com/youtube/v3/'
      var api_key = 'AIzaSyBCsZis58Ctu-6dBv1TnKWbgXtjUkwj2Nw'
      var next_token_string = ''
      var max_results = '&maxResults=50'
      var video_id_list = []
      var done = false

      this.setState({requesting: true})

      while (!done) {
        await fetch(base_url + 'playlistItems?part=snippet%2C+id&playlistId=' + this.state.form_input + '&key='+api_key + next_token_string+max_results)
          .then((r) => r.json())
          .then((data) => {
            var item
            for (item of data['items']) {
              video_id_list.push(item['snippet']['resourceId']['videoId'])
            }
            if (data.hasOwnProperty('nextPageToken')) {
              next_token_string = '&pageToken=' + data['nextPageToken']
            }
            else {
              done = true
            }
        })
      }

      this.setState({fetched_ids: true})

      var index = 0
      var end_index
      var video_ids_for_request
      var duration_string
      var duration_data
      var video_id_list_durations = new Set()
      var video_id_list_names = new Set()
      var video_id_list_thumbnails = new Set()
      var valid_id_list = new Set()

      while (index < video_id_list.length) {
          end_index = index + 50
          if (end_index >= video_id_list.length) {
              end_index = video_id_list.length
          }
          video_ids_for_request = video_id_list.slice(index, end_index).join()
          await fetch(base_url + 'videos?part=snippet&id='+video_ids_for_request+'&key='+api_key+'&part=contentDetails&maxResults=50')
          .then((r) => r.json())
          .then((data) => {
            var item
            for (item of data['items']) {
              duration_string = item['contentDetails']['duration']
              duration_data = duration_string.match(/\d+/g)
              if (duration_string.includes('M') && duration_string.includes('S')) {
                video_id_list_durations.add(duration_data.map(x => parseInt(x)))
              }
              else if (duration_string.includes('M')) {
                video_id_list_durations.add([parseInt(duration_data[0]), 0])
              }
              else {
                video_id_list_durations.add([0, parseInt(duration_data[0])])
              }
              video_id_list_names.add(item['snippet']['title'])
              video_id_list_thumbnails.add(item['snippet']['thumbnails']['default']['url'])
              valid_id_list.add(item['id'])
            }
            index += 50
            this.setState({fetched_information: index / video_id_list.length * 100})
          })
      }
      video_id_list_durations = Array.from(video_id_list_durations)
      video_id_list_names = Array.from(video_id_list_names)
      video_id_list_thumbnails = Array.from(video_id_list_thumbnails)
      valid_id_list = Array.from(valid_id_list)

      var video_dict = {}
      var i
      for (i=0; i < video_id_list_names.length; i++) {
        video_dict[video_id_list_names[i]] = {'Duration': video_id_list_durations[i], 'Id': valid_id_list[i], 'Thumbnail': video_id_list_thumbnails[i]}
      }
      this.setState({requesting: false, playlist_id: this.state.form_input, video_names: video_id_list_names, video_dict: video_dict, showing_plain_text: true,
                      video_ids: valid_id_list, video_durations: video_id_list_durations, video_thumbnails: video_id_list_thumbnails})
    }
    render() {
      if (this.state.requesting) {
        return (
          <div className='FetchingScreen'>
            <h1>Fetching Youtube data</h1>
            {this.state.fetched_ids === false ?
              <div className="FetchingInfo">
                <div className='LoadingSquare'/>
                <h3 style={{marginLeft: '30px'}}>Fetching Video IDs</h3>
              </div>
            :
              <div>
                <div className="FetchingInfo">
                  <div className='check'/>
                  <h3 style={{marginLeft: '30px'}}>Fetched Video IDs</h3>
                </div>
                <h3>Fetching Video Information from IDs</h3>
                <ProgressBar variant="info" now={this.state.fetched_information}/>
              </div>
            }
          </div>
        );
      }
      if (this.state.playlist_id.length !== 0) {
        return (
            <div className='Itsa-playlist'>
                <TitleComponent></TitleComponent>
                <MainContainer shuffle={this.state.shuffle} shuffle_completed={this.shuffle_pop}video_names={this.state.video_names} video_dict={this.state.video_dict} 
                  searched_song_name={this.state.searched_song_name} clear_search={this.clear_search}>
                </MainContainer>
                <BottomBar shuffle={this.shuffle_push} video_dict={this.state.video_dict} video_names={this.state.video_names} search_complete={this.search_completed}></BottomBar>
            </div>
          );
      }
      else {
        return this.construct_playlist_submission()
      }
    }
  }

export default ItsaPlaylist