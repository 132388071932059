import React from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import SongCard from './song_card'

class BottomBar extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        show_modal: false,
        search_song: '',
        search_matches: []
      }
      this.close_modal = this.close_modal.bind(this)
      this.open_modal = this.open_modal.bind(this)
      this.form_input = this.form_input.bind(this)
      this.search = this.search.bind(this)
      this.construct_song_cards = this.construct_song_cards.bind(this)
    }
    close_modal(search=false) {
      var search_song = ''
      var search_matches = []
      var show_modal = false
      if (search && this.state.search_song.length > 0) {
        search_song = this.state.search_song
        search_matches = this.search(search_song)
        show_modal = true
      }
      this.setState({show_modal: show_modal, search_song: search_song, search_matches: search_matches})
    }
    open_modal() {
      this.setState({show_modal: true})
    }
    form_input(event) {
      this.setState({search_song: event.target.value})
    }
    search(song_name) {
      var looper
      var matches = []
      for (looper of this.props.video_names) {
        if (looper.toLowerCase().includes(song_name.toLowerCase())) {
          matches.push(looper)
        }
      }
      return matches 
    }
    construct_song_cards() {
      return this.state.search_matches.map(song => {
        return (
          <SongCard song_name={song} key={song+'-search'} thumbnail={this.props.video_dict[song]['Thumbnail']}
            play={(song_name) => {
              this.props.search_complete(song_name)
              this.setState({show_modal: false, search_song: '', search_matches: []})
            }}>
          </SongCard>
        );
      })
    }
    render() {
      return (
        <>
          <div className='Search-bar'>
              <h3>Already Played</h3>
              <div>
                <Button variant="outline-light" style={{marginRight: '10px'}} onClick={() => this.open_modal()}>Search</Button>
                <Button variant="outline-light" onClick={this.props.shuffle}>Shuffle</Button>
              </div>
              <h3>Upcoming Queue</h3>
          </div>
          {this.state.search_matches.length === 0 ?
            <Modal className='Search-modal' show={this.state.show_modal} onHide={() => this.close_modal()}>
            <Modal.Header>
              <Modal.Title>Search for a song to play</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="search_song_name">
                  <Form.Control onChange={this.form_input} placeholder="Song Name" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => this.close_modal()}>
                Close
              </Button>
              <Button variant="outline-info" onClick={() => this.close_modal(true)}>
                Search
              </Button>
            </Modal.Footer>
          </Modal>
          :
          <Modal className='Search-modal' show={this.state.show_modal} onHide={() => this.close_modal()}>
            <Modal.Header>
              <Modal.Title>Search Results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="Modal-search-results">
                {this.construct_song_cards()}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={() => this.close_modal()}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          }
          
        </>
      );
    }
  }

export default BottomBar