import React from 'react';
import Youtube from 'react-youtube';

class YoutubeBox extends React.Component {
    render() {
      const opts = {
        playerVars: {
          autoplay: 1
        }
      }
      return (
        <div className='Youtube-box'>
            {this.props.song === '' ? 
              <h1>Click a video on the right!</h1>
              :
              <div className='video-container'> 
                <Youtube videoId={this.props.dict[this.props.song]['Id']} opts={opts} onStateChange={(state) => {if (state.data === 0) this.props.play()}}></Youtube>
              </div>}
        </div>

      );
    }
  }

export default YoutubeBox