import React from 'react';
import Card from "react-bootstrap/Card"
import './react_components.css'

class SongCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            color: 'dark'
        }
        this.turn_light = this.turn_light.bind(this)
        this.turn_dark = this.turn_dark.bind(this)
    }
    turn_light() {
        this.setState({color: 'secondary'})
    }
    turn_dark() {
        this.setState({color: 'dark'})
    }
    render() {
      return (
        <div onClick={() => this.props.play(this.props.song_name)} onMouseEnter={() => this.turn_light()} onMouseLeave={() => this.turn_dark()}>
            <Card bg={this.state.color} style={{width: '100%', fontSize: '12px', color: 'white'}}>
                <Card.Body style={{display: 'flex', padding: '10px', alignItems: 'center', justifyContent: 'space-around'}}>
                    <img src={this.props.thumbnail} style={{'margin': '10px'}}/>
                    <Card.Text style={{'text-align': 'center', fontSize: '1.2em', width: '100%'}}> {this.props.song_name} </Card.Text>
                </Card.Body>
            </Card>
        </div>
      );
    }
  }

export default SongCard